<template>
    <div>
        <img class="htopImg vk-bg4"  alt="">
        <section style="background:#FAFAFA;">
            <div class="container">
                <div class="row">
                    <div class="gridBox">
                        <div class="clearBoth">
                            <div class="news">
                                <div class="newsImg ">
                                    <img src="@/assets/images/pexels-lukas-296278.jpg" alt="">
                                </div>
                            </div>

                            <div class="news">
                                <div class="newsImg p2" ><img src="@/assets/images/p2.jpg" alt=""></div>
                            </div>

                            <div class="news"> 
                                <div class="newsImg p3"><img src="@/assets/images/slider.jpg" alt=""></div>
                            </div>

                            <div class="news">
                                <div class="newsImg p4"><img src="@/assets/images/p4.jpg" alt=""></div>
                            </div>
                        </div>
            <div class="mPdding">
                <h5>{{$t('pTeam')}}</h5><br/>
                <p>{{$t('TeamMsg')}}</p><br/>
                <p>{{$t('TeamMsg2')}}</p>
            </div>
        </div>
                </div>
            </div>
        </section>
        <br>
        <br>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
    .htopImg {
        width: 100%;
        margin: auto;
        display: block;
        height: 33vw;
    }

      .gridBox {
        width: 70%;
        margin: 5vw auto;
        /* border: solid red 1px; */
        display: grid;
        grid-template-columns: 50% 50%;
    }
    .news {
        width: 40%;
        margin-left: 5%;
        margin-bottom: 5%;
        /* background: #CCC; */
        float: left;
    }
    .newsImg {
        width: 100%;
        height: 100%;
        background-size: 100% 100% !important;
        background-repeat: no-repeat;
        background-position:center;
    }
    .newsImg img {
        width: 100%;
        height: 100%;
    }
    .p1 {
        background:url('../assets/images/p1.jpg') no-repeat;
    }
    .p2 {
        background:url('../assets/images/p2.jpg') no-repeat;
    }
    .p3 {
        background:url('~@/assets/images/p3.jpg') no-repeat;
    }
    .p4 {
        background:url('~@/assets/images/p4.jpg') no-repeat;
    }
</style>